'use client';

import type { ReactNode } from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { Link } from '@/components/atoms/Link';
import styles from './index.module.scss';

export default function PricingTableClient({
  moreLabel,
  lessLabel,
}: {
  moreLabel: string;
  lessLabel: string;
}): ReactNode {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classNames(isExpanded ? '' : 'toggle', styles.toggleLink)}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        type="S"
        iconId={isExpanded ? 'arrows/chevron-up' : 'arrows/chevron-down'}
        onClick={toggle}
        aria-expanded={isExpanded}
        aria-controls="pricingTable"
        href=""
        mode="dark"
        className={styles.toggleAnchor}
      >
        {isExpanded ? lessLabel : moreLabel}
      </Link>
    </div>
  );
}
