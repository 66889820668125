import Cookies from 'js-cookie';
import { getCookieOptions } from '@/util/getCookieOptions';

const NOT_HOME_PAGE = Object.freeze([
  '/contact-us',
  '/about-us',
  '/our-services',
  '/cars',
  '/how-it-works',
  '/partners',
  '/terms-conditions',
  '/privacy-policy',
  '/error',
]);

const NOT_HOME_PAGE_STARTS_WITH = Object.freeze(['/checkout', '/customer', '/login']);

const isSomeHomePage = () =>
  !NOT_HOME_PAGE_STARTS_WITH.some((startPathname) => window.location.pathname.startsWith(startPathname)) &&
  !NOT_HOME_PAGE.includes(window.location.pathname);

export const setFixterLanding = (secureCookies = false) => {
  const pathname = window.location.pathname.replace(/\/$/, ''); // pathname without trailing slash
  if (isSomeHomePage() && !Cookies.get('fixterLanding')) {
    const options = getCookieOptions('fixterLanding', window.location.hostname, secureCookies);
    Cookies.set('fixterLanding', pathname, options);
  }
};
