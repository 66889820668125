'use client';

import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { getCookieOptions } from '@/util/getCookieOptions';
import { setFixterLanding } from './cookies';

interface PageSetupEffectsClientProps {
  readonly secureCookies?: boolean;
  readonly campaignId?: string;
  readonly awc?: string;
}

export function PageSetupEffectsClient({
  secureCookies = false,
  campaignId,
  awc,
}: PageSetupEffectsClientProps): null {
  useEffect(() => {
    if (campaignId) {
      const options = getCookieOptions('fixterMarketingCampaign', window.location.hostname, secureCookies);
      Cookies.set('fixterMarketingCampaign', campaignId, options);
    }
    if (awc) {
      const options = getCookieOptions('awc', window.location.hostname, secureCookies);
      Cookies.set('awc', awc, options);
    }

    setFixterLanding(secureCookies);

    Cookies.remove(
      'fixterMembershipGarage',
      getCookieOptions('fixterMembershipGarage', window.location.hostname, secureCookies)
    );
  }, [awc, campaignId, secureCookies]);

  return null;
}
